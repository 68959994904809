import React from 'react';
import { ReactTypeformEmbed } from 'react-typeform-embed'
import { FormattedMessage, useIntl } from 'react-intl';
import { defaultFormatValues } from '../../../data/defaultFormat'

import './styles.scss';
import { OutboundLink } from 'gatsby-plugin-google-gtag';

const ComplaintsForm: React.FC = () => {

  const intl = useIntl()

  return (
    <div className="bg-complaints">
    <section className="container">
      <div className="row d-flex justify-content-center">
        <div className="col-md-6 p-7">
          <OutboundLink href='https://form.typeform.com/to/cd4Q0Fwo' className="typeform_button">
            Comenzar
          </OutboundLink>
          <div className="mt-layout-6">
            <p className="p-4 notice-alt mt-4 text-center">
              <b><FormattedMessage id="ethic_line.hero.anonymity_disclaimer"/></b>
            </p>
          </div>
        </div>
      </div>
    </section>
    </div>
  );
};

export default ComplaintsForm;
