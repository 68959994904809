import React from 'react';
import { FormattedMessage } from 'react-intl';
import { defaultFormatValues } from '../../../data/defaultFormat'
import HeroImg from './assets/images/hero.inline.svg';

import './styles.scss'

const ComplaintsHero: React.FC = () => {
  return (
    <section className="complaints-hero bg-complaints">
      <div className="container hero pb-0">
        <div className="row">
          <div className="col-sm-12 col-md-6 complaints-hero__text">
            <h2 className="complaints-hero__title display-4 text-primary">
              <FormattedMessage id="complaints.hero.title" values={defaultFormatValues} />
              </h2>
              <p className="complaints-hero__description text-justify">
                <FormattedMessage id="complaints.hero.description" values={defaultFormatValues} />
              </p>
          </div>
          <div className="col-sm-12 col-md-6 d-flex justify-content-end complaints-hero_img">
            <HeroImg className="complaints-hero__img" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default ComplaintsHero;
