import React from 'react';
import { FormattedMessage } from 'react-intl';
import { defaultFormatValues } from '../../../data/defaultFormat';


import './styles.scss';

const ComplaintsContent: React.FC = () => {

  return (
    <section className="bg-complaints">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-md-6 content__panel-1">
            <ul>
              <li className="my-5 lead"><FormattedMessage id="complaints.content.list1.item1" /></li>
              <li className="my-5 lead"><FormattedMessage id="complaints.content.list1.item2" /></li>
              <li className="my-5 lead"><FormattedMessage id="complaints.content.list1.item3" /></li>
              <li className="my-5 lead"><FormattedMessage id="complaints.content.list1.item4" /></li>
              <li className="my-5 lead"><FormattedMessage id="complaints.content.list1.item5" /></li>
              <li className="my-5 lead"><FormattedMessage id="complaints.content.list1.item6" /></li>
              <li className="my-5 lead"><FormattedMessage id="complaints.content.list1.item7" /></li>
            </ul>
          </div>
          <div className="col-sm-12 col-md-6 content__panel-2">
            <ul>
              <li className="my-5 lead"><FormattedMessage id="complaints.content.list2.item1" /></li>
              <li className="my-5 lead"><FormattedMessage id="complaints.content.list2.item2" /></li>
              <li className="my-5 lead"><FormattedMessage id="complaints.content.list2.item3" /></li>
              <li className="my-5 lead"><FormattedMessage id="complaints.content.list2.item4" /></li>
              <li className="my-5 lead"><FormattedMessage id="complaints.content.list2.item5" /></li>
              <li className="my-5 lead"><FormattedMessage id="complaints.content.list2.item6" /></li>  
            </ul>
          </div>
        </div>
        <div className="row d-flex justify-content-center">
          <div className="col-md-4 content-card mt-layout-2">
            <div>
              <div>
                <div>
                  <p className="card-text text-center"><FormattedMessage id="complaints.content.card.description" values={defaultFormatValues}/></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ComplaintsContent;
