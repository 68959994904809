import React from 'react';
import ComplaintsContent from '../components/ComplaintsChannel/ComplaintsContent/ComplaintsContent';
import ComplaintsForm from '../components/ComplaintsChannel/ComplaintsForm/ComplaintsForm';
import ComplaintsHero from '../components/ComplaintsChannel/ComplaintsHero/ComplaintsHero';
import Footer from '../components/Footer/Footer';
import SEO from '../components/SEO/SEO';
import { LocaleTypes } from '../data/locales';
import IndexLayout from '../layouts';

interface ComplaintsChannelProps {
  pathContext: {
    localeCode: LocaleTypes;
  };
  location: Location;
}

const complaintschannel: React.FC<ComplaintsChannelProps> = ({location, pathContext}) => {

    const content = () => (
      <>

        <SEO titleId="complaints.title" />

        <ComplaintsHero />
        <ComplaintsContent />

        <ComplaintsForm />

        <Footer theme="secondary" />
      </>
    )

  return (
    <IndexLayout 
      render={content}
      locale={pathContext.localeCode}
      navbarTheme='default'
      location={location}/> 
  );
};

export default complaintschannel;
